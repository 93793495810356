@import url(https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300&display=swap);
body {
  background-color: #0a6630;
  background-image: url(/static/media/background_new_landscape_tilable.7ddcea08.jpg);
  background-size: 1920px;
  font-weight:300;
  -webkit-text-size-adjust: none;
  color: white;
  font-size: 100%;
}

.App_appBody__1BfQI {
  color: white;
  font-family: 'M PLUS 1p', sans-serif;
  max-width: 2000px;
  margin: 0 auto;
}

h1, h2, h3, p {
  margin: 0 0 15px 0;
  padding: 0;
  min-height: 0;
}

h1 {
  font-size: clamp(1.4rem, 0.3000rem + 1.6000vw, 2.2rem);
  font-weight: 300;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: clamp(1.2rem, 0.3000rem + 1.6000vw, 2rem);
  font-weight: 300;
}

h3 {
  font-size: clamp(1.1rem, 0.3000rem + 1.6000vw, 1.8rem);
  font-weight: 300;
}

.App_landingpageTitle__2uohg {
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 20px;
}

.App_hubLink__12Qov {
  text-align: center;
}

.App_layoutLimiter__voZ0h {
  max-width: 1200px;
  margin: auto;
}

.App_layoutWrapper__zyJA5 {
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  justify-content: center;
  min-height: 100vh;
}

.App_mainDisplay__1UOIS {
  background: rgba(0,0,0,.5);
  padding: 20px;
  flex: 0 1 auto;
}

a:visited {
  color: white;
}
.ExhibitionHub_menu__2o9ae {
    display: flex;
}

.ExhibitionHub_topicListWrapper__3NmRK {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ExhibitionHub_topicListEntry__1kQdj {
    cursor: pointer;
}

.ExhibitionHub_explorerWrapper__2bXFQ  {
    flex: 1 1 auto;
}

.ExhibitionHub_active__15Y67 {
    color: rgb(255, 120, 120);
}
.ContentPages_headerTitle__2K3wu {
    text-transform: uppercase;
    margin: 20px 0;
    text-shadow: white 0px 0px 10px;
}

.ContentPages_displayContent__JmycM {
    font-size: clamp(0.9rem, 0.3000rem + 1.6000vw, 1.25rem);
    margin-bottom: 70px;
}

.ContentPages_displayContent__JmycM a {
    color: white;
}
.ContentPages_displayContent__JmycM a:visited {
    color: white;
}

.ContentPages_footerWrapper__3OlQq {
    position: fixed;
    height: 50px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.75) 75%, rgba(0,0,0,0) 100%);
}

.ContentPages_footerLimiter__FD0BE {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ContentPages_homeNavWrapper__2eJM8 {
    flex: 0 1 auto;
    max-width: 25%;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
}

.ContentPages_footerHomeIcon__3OqJ9 {
    flex: 0 1 auto;
    height: 70%;
}

.ContentPages_footerHomeLabel__3zOBa {
    flex: 1 1 auto;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: small;
    text-transform: uppercase;
}

.ContentPages_footerTitleWrapper__2GOYS {
    flex: 0 1 auto;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    font-size: small;
}

.ContentPages_hideOverflow__2779y {
    overflow: hidden;
    text-overflow: ellipsis;
}

.ContentPages_footerElemWrapper__1Xi0Y {
    flex: 1 1 auto;
    margin-left: 2px;
    margin-right: 2px;
}

.ContentPages_footerNavElemWrapper__3c4ex {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ContentPages_footerNavElem__1WZnF {
    border-radius: 15px;
    border: 1px solid white;
    width: 15px;
    height: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContentPages_activeNavElem__1OPXH {
    position: relative;
    background-color: white;
    width: 50%;
    height: 50%;
    border-radius: 20px;
}

.ContentPages_pageTitle__eqL0K {
    background: #365;
    text-align: left;
    padding: .2em;
    margin-top: 40px;
}

.ContentPages_mediaWrapper__d5IM5 {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.ContentPages_smallMediaContent__pzJp6 {
    height: 10vh;
    min-height: 50px;
}

.ContentPages_mediumMediaContent__2PcUf {
    height: 300px;
    max-height: 40vh;
}

.ContentPages_largeMediaContent__WW_Vw {
    max-height: 70vh;
}

.ContentPages_imageCaption__Nx1DK {
    font-size: smaller;
    font-style: italic;
}

.ContentPages_centerText__2DQKi {
    text-align: center;
}

.ContentPages_overlaySliderBox__1De0c {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    border: 1px solid white;
    height: 25px;
    display: flex;
}
.ContentPages_overlaySliderBox__1De0c input {
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: text-top;
}

.ContentPages_mediaContent__2tvoM {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
}
  
.ContentPages_canvasContent__PJ0oa {
    width: 100%;
    height: 70vh;
}

.ContentPages_invert__3hpHg {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.ContentPages_introButton__N7smv {
    display: block;
    border: 1px solid white;
    padding: 10px;
    margin: 0 auto;
    margin-top: 10px;
    max-width: 60%;
    text-decoration: none;
    text-align: center;
    background: rgba(255,255,255,0.2);
}

.ContentPages_gameButton__1em0B {
    border: 1px solid white;
    color: white;
    padding: 5px;
    margin: 0 auto;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: clamp(0.8rem, 0.3000rem + 1.6000vw, 1.2rem);
    max-width: 60%;
    text-decoration: none;
    text-align: center;
    background: rgba(255,255,255,0.2);
    cursor: pointer;
}

.ContentPages_gameButton__1em0B:disabled {
    color: rgb(128,128,128);
    background: rgba(128,128,128,0.4);
}

.ContentPages_floatingImage__3dIRH {
    float: left;
    padding-right: 10px; 
    padding-bottom: 10px;
    height: 300px;
}

.ContentPages_navArrow__29MqF {
    position: fixed;
    top: 50%;
    transform: translate(0,-50%);
    height: 30%;
    cursor: pointer;
}

.ContentPages_navArrow__29MqF img {
    width: 100%;
    max-width: 2.5vw;
    height: 100%;
    object-fit: contain;
    opacity: 1;
}

.ContentPages_leftArrow__1ffVd {
    transform: rotate(180deg) translate(0,50%);
    left: 10px;
}

.ContentPages_rightArrow__18Rrh {
    right: 10px;
}

.ContentPages_navArrowLabel__gm4aQ {
    position: absolute;
    font-size: small;
    text-transform: uppercase;
    white-space: nowrap;
    top: 50%;
}

.ContentPages_navArrowLabelRight__2rI4X {
    transform: translate(-50%, -50%) rotate(90deg);
    left: -10px;
}

.ContentPages_navArrowLabelLeft__dUqY4 {
    transform: translate(-65%, -50%) rotate(-90deg);
}

.ContentPages_backHubArrow__2-uY1 img {
    -webkit-filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(300deg);
            filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(300deg)
}

.ContentPages_hidden__1m4EU {
    display: none;
}
.FeynmanDiagrams_headerNavigationWrapper__3EPS3 {
    display: flex;
    flex-direction: row;
}

.FeynmanDiagrams_headerNavigationSpacer__2r7-f {
    flex: 0 1 auto;
    width: 10px;
    border-bottom: 1px solid white;
}

.FeynmanDiagrams_headerNavigationElement__1_cHG {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top: 1px solid white;
    min-height: 40px;
    background-color: rgba(255,255,255,0.3);
    cursor: pointer;
}

.FeynmanDiagrams_headerNavigationFiller__34IcH {
    flex: 1 1 auto;
    border-bottom: 1px solid white;
}

.FeynmanDiagrams_sketchpadWrapper__2xX4R {
    display: flex;
    flex-direction: column;
    background-color: rgba(255,255,255,0.3);
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    padding: 1.2em;
}

.FeynmanDiagrams_introImagesWrapper__188qy {
    text-align: center;
    width: 100%;
}

.FeynmanDiagrams_introImages__wfGAO {
    -webkit-filter: invert(1);
            filter: invert(1);
    padding-right: 10px;
    padding-left: 10px;
}

.FeynmanDiagrams_introControls__1dUdN {
    padding-bottom: 20px;
    text-align: center;
}
.FeynmanDiagrams_introControls__1dUdN button {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    border: 1px solid white;
    background-color: rgba(255,255,255,0.6);
    cursor: pointer;
}
.FeynmanDiagrams_introControls__1dUdN button:disabled {
    border: 1px solid rgb(141, 141, 141);
    background-color: rgba(117, 117, 117, 0.6);
}

.FeynmanDiagrams_toolboxWrapper__3YdZB {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 20px;
}
.FeynmanDiagrams_theorySelector__314yf {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}
.FeynmanDiagrams_toolSelector__2V7tb {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
}
.FeynmanDiagrams_sketchpadControlButtons__24zAf {
    flex: 0 1 auto;
    margin: 10px 5px 0 5px;
    height: 2rem;
}
.FeynmanDiagrams_resetButton__126VC {
    border: 1px solid red;
    color: red;
    background-color: rgba(255,0,0,0.3);
    cursor: pointer;
}
.FeynmanDiagrams_checkButton__VVrOo {
    border: 1px solid rgb(173, 255, 47);
    color: #adff2f;
    background-color: rgba(173, 255, 47,0.3);
    cursor: pointer;
}

.FeynmanDiagrams_sketchpadBody__1Uk-v {
    border-bottom: 1px solid white;
    padding-bottom: 10px;
}


.FeynmanDiagrams_sketchpadFooter__x8CyM {
    min-height: 50px;
    display: flex;
    flex-direction: row;
}
.FeynmanDiagrams_infoTextWrapper__bP-1X  {
    flex: 1 1 auto;
    color: white;
}
.FeynmanDiagrams_footerControlsWrapper__3bcyY {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.FeynmanDiagrams_selectedButton__21t1h {
    background-color: rgba(255,255,255,0.6);
}



.FeynmanDiagrams_inactive__39C_t {
    border-bottom: 1px solid white;
    background-color: rgba(255,255,255,0.6);
}

.FeynmanDiagrams_errorText__3HwyR {
    color: red;
}

.FeynmanDiagrams_sketchControlButtons__JwUsN {
    margin-left: 10px;
    margin-right: 10px;
}

.FeynmanDiagrams_timeArrow__3flIu {
    height: 75px;
    width: 100%;
    text-align: center;
}

.FeynmanDiagrams_timeArrowImg__3SbuN {
    height: 100%;
    width: 100%;
    object-fit: contain;
}


.FeynmanQuiz_quizWrapper__34E2T {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.FeynmanQuiz_quizWrapper__34E2T b {
    color: rgb(255, 199, 78);
}

.FeynmanQuiz_questionNavigation__PZ49o {
    flex: 0 1 auto;
    min-width: 50px;
    display: flex;
    flex-direction: row;
}
.FeynmanQuiz_questionNavigationElem__3TDTU {
    border: 1px solid white;
    border-bottom: none;
    text-align: center;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(255,255,255,0.3);
}

.FeynmanQuiz_questionNavigationSpacer__1F2Lw {
    flex: 0 1 auto;
    width: 10px;
    border-bottom: 1px solid white;
}

.FeynmanQuiz_questionNavigationElem__3TDTU:hover {
    cursor: pointer;
    background-color: rgba(255,255,255,0.5);
}

.FeynmanQuiz_questionWrapper__1ZIpm {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.FeynmanQuiz_questionTitle__3ZJ5Q {
    margin: 0;
}

.FeynmanQuiz_questionIntroduction__t5TUB {
    flex: 1 1 auto;
    padding: 10px;
    background-color: rgba(255,255,255,0.3);
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
}

.FeynmanQuiz_taskDescription__28__y {
    font-style: italic;
    font-size: smaller;
    margin-top: 20px;
}

.FeynmanQuiz_blobImageWrapper__36cvZ {
    width: calc(100% - 12px);
    height: 30vh;
    text-align: center;
}

.FeynmanQuiz_blobImageWrapper__36cvZ img {
    height: 100%;
    -webkit-filter: invert(1);
            filter: invert(1);
    max-width: 100%;
}

.FeynmanQuiz_questionAnswerGrid__1jLMc {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    background-color: rgba(255,255,255,0.3);
    padding: 10px;
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
}

.FeynmanQuiz_questionAnswerGridCell__-OnLb {
    border: 1px solid white;
    padding: 5px;    
    max-height: 20vh;
    text-align: center;
    cursor: pointer;
}

.FeynmanQuiz_questionAnswerGridCell__-OnLb img {
    height: 100%;
    width: 100%;
    -webkit-filter: invert(1);
            filter: invert(1);
}

.FeynmanQuiz_summaryWrapper__1my8K {
    border: 1px solid white;
    border-top: none;
    background-color: rgba(255,255,255,0.3);
    padding: 10px;
    width: calc (100% - 22px);
    min-height: calc(100% - 22px);
}

.FeynmanQuiz_summaryGrid__2kGBe {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}

.FeynmanQuiz_quizControls__1Cl94 {
    flex: 0 1 auto;
    text-align: center;
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
    background-color: rgba(255,255,255,0.3);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FeynmanQuiz_quizButton__1wbu- {
    display: block;
    max-width: 300px;
    height: 50px;
    cursor: pointer;
    padding: 0 20px;
}

.FeynmanQuiz_checkboxButton__15x5N {
    height: 50px;
    margin-top: 25px;
}

.FeynmanQuiz_checkButton__1fDpn {
    border: 1px solid red;
    color: red;
    background-color: rgba(255,0,0,0.3);
}

.FeynmanQuiz_nextButton__2aGFF {
    border: 1px solid rgb(173, 255, 47);
    color: #adff2f;
    background-color: rgba(173, 255, 47,0.3);
}

.FeynmanQuiz_rulesBox__I8oii {
    background-color: rgba(255,255,255,0.3);
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid white;
    border-top: none;
    padding-bottom: 40px;
}

.FeynmanQuiz_rulesWrapper__1AMU_ {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.FeynmanQuiz_ruleContainer__3ym1_ {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    min-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
}

.FeynmanQuiz_ruleContainer__3ym1_ img {
    flex: 1 1 auto;
    max-height: 125px;
    -webkit-filter: invert(1);
            filter: invert(1);
}

.FeynmanQuiz_ruleWrapperTitle__13xfp {
    flex: 0 1 auto;
    text-align: center;
    text-decoration: underline;
}

.FeynmanQuiz_fullOverlay__3l4mN {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FeynmanQuiz_overlayBox__291zK {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid white;
    background-color: rgba(255,255,255,0.3);
    max-width: 80vh;
    padding: 20px;
    margin: 0 auto;
}
.FeynmanQuiz_overlayBox__291zK img {
    max-height: 50vh;
    max-width: 100%;
    -webkit-filter: invert(1);
            filter: invert(1);
}
.FeynmanQuiz_overlayBoxTitle__Pk_ON {
    margin: 0;
}

.FeynmanQuiz_closeOverlayButton__I2vaV {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.FeynmanQuiz_closeOverlayButton__I2vaV img {
    width: 100%;
    height: 100%;
    -webkit-filter: invert(0);
            filter: invert(0);
}

.FeynmanQuiz_resetButton__3MQTN {
    border: 1px solid red;
    color: red;
    background-color: rgba(255,0,0,0.3);
    cursor: pointer;
    margin-top: 20px;
}

.FeynmanQuiz_inactive__3H1hU {
    background-color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid white;
}

.FeynmanQuiz_selected__37zTs {
    background-color: rgba(255, 255, 255, 0.5);
}
.BeyondFeynman_gameWrapper__2ZsBQ {
    height: 60vh;
}

.BeyondFeynman_gameCanvas__2lfZc {
    width: 100%;
    height: 100%;
}

.BeyondFeynman_operationMenuWrapper__2IzPn {
    position: absolute;
    width: 50%;
    text-align: center;
}

.BeyondFeynman_hide__3wpju {
    display: none;
}

.BeyondFeynman_tesselationWrapper__drRmA {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.BeyondFeynman_flipImageWrapper__FwflO {
    position: relative;
}

.BeyondFeynman_tesselationControls__1Bf1Q {
    text-align: center;
    object-fit: contain;
    height: 40px;
    padding: 20px;
}

.BeyondFeynman_hexTesselationControlPosition___uxFX {
    position: absolute;
    bottom: 0;
}

.BeyondFeynman_hexagonRenderer__1vqSs {
    width: 100%;
    height: 100%;
}

.BeyondFeynman_fillingImage__eYuJ5 {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
}

.BeyondFeynman_fadeIn__3pp2_ {
    -webkit-animation: BeyondFeynman_fadeIn__3pp2_ 0.75s forwards;
            animation: BeyondFeynman_fadeIn__3pp2_ 0.75s forwards;
}

.BeyondFeynman_fadeOut__6r_E1 {
    -webkit-animation: BeyondFeynman_fadeOut__6r_E1 0.25s forwards;
            animation: BeyondFeynman_fadeOut__6r_E1 0.25s forwards;
}
  
@-webkit-keyframes BeyondFeynman_fadeOut__6r_E1 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
  
@keyframes BeyondFeynman_fadeOut__6r_E1 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes BeyondFeynman_fadeIn__3pp2_ {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes BeyondFeynman_fadeIn__3pp2_ {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.PhysicalBasics_gameWrapper__3hPj- {
    width: 100%;
    height:100%;
}

.PhysicalBasics_gameControls__1-oJn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}

.PhysicalBasics_iconButton__27Nse {
    flex: 1 1 auto;
    background-color: #fff;
    margin: 10px;
    max-width: 100px;
    cursor: pointer;
}

.PhysicalBasics_iconButton__27Nse img {
    width: 100%;
}

.PhysicalBasics_hidden__1uObr {
    display: none;
}
.Team_teamWrapper__3dGhT {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.Team_teamMember__3g5bb {
    flex: 0 1 auto;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.Team_avatarContainer__1Ux76 {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
}

.Team_avatarContainer__1Ux76 img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Team_memberName__1T52A {
    flex: 1 1 auto;
    text-align: center;
    white-space: nowrap;
    font-size: smaller;
}

.Team_memberRole__1p5-6 {
    font-style: italic;
    font-size: x-small;
}
.SideMenu_wrapper__18cKE {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 30px;
    pointer-events: none;
    color: black;
    padding-top: 20px;
}

.SideMenu_wrapper__18cKE a {
    color: black;
    text-decoration: none;
}

.SideMenu_wrapper__18cKE a:visited {
    color: black;
}

.SideMenu_sideMenuButton__2FTo_ {
    flex: 0 1 auto;
    margin-top: 10px;
    pointer-events: all;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.75rem;
}

.SideMenu_hidden__2EkWf {
    display: none;
}

.SideMenu_highlight__2ipgH {
    -webkit-animation: SideMenu_shake__ewyxv 0.5s both;
            animation: SideMenu_shake__ewyxv 0.5s both;
    transform: translate3d(0, 0, 0);
}

@-webkit-keyframes SideMenu_shake__ewyxv {
    0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes SideMenu_shake__ewyxv {
    0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
