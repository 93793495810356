.quizWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.quizWrapper b {
    color: rgb(255, 199, 78);
}

.questionNavigation {
    flex: 0 1 auto;
    min-width: 50px;
    display: flex;
    flex-direction: row;
}
.questionNavigationElem {
    border: 1px solid white;
    border-bottom: none;
    text-align: center;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(255,255,255,0.3);
}

.questionNavigationSpacer {
    flex: 0 1 auto;
    width: 10px;
    border-bottom: 1px solid white;
}

.questionNavigationElem:hover {
    cursor: pointer;
    background-color: rgba(255,255,255,0.5);
}

.questionWrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.questionTitle {
    margin: 0;
}

.questionIntroduction {
    flex: 1 1 auto;
    padding: 10px;
    background-color: rgba(255,255,255,0.3);
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
}

.taskDescription {
    font-style: italic;
    font-size: smaller;
    margin-top: 20px;
}

.blobImageWrapper {
    width: calc(100% - 12px);
    height: 30vh;
    text-align: center;
}

.blobImageWrapper img {
    height: 100%;
    filter: invert(1);
    max-width: 100%;
}

.questionAnswerGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    background-color: rgba(255,255,255,0.3);
    padding: 10px;
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
}

.questionAnswerGridCell {
    border: 1px solid white;
    padding: 5px;    
    max-height: 20vh;
    text-align: center;
    cursor: pointer;
}

.questionAnswerGridCell img {
    height: 100%;
    width: 100%;
    filter: invert(1);
}

.summaryWrapper {
    border: 1px solid white;
    border-top: none;
    background-color: rgba(255,255,255,0.3);
    padding: 10px;
    width: calc (100% - 22px);
    min-height: calc(100% - 22px);
}

.summaryGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}

.quizControls {
    flex: 0 1 auto;
    text-align: center;
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
    background-color: rgba(255,255,255,0.3);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quizButton {
    display: block;
    max-width: 300px;
    height: 50px;
    cursor: pointer;
    padding: 0 20px;
}

.checkboxButton {
    height: 50px;
    margin-top: 25px;
}

.checkButton {
    border: 1px solid red;
    color: red;
    background-color: rgba(255,0,0,0.3);
}

.nextButton {
    border: 1px solid rgb(173, 255, 47);
    color: #adff2f;
    background-color: rgba(173, 255, 47,0.3);
}

.rulesBox {
    background-color: rgba(255,255,255,0.3);
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid white;
    border-top: none;
    padding-bottom: 40px;
}

.rulesWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.ruleContainer {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    min-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
}

.ruleContainer img {
    flex: 1 1 auto;
    max-height: 125px;
    filter: invert(1);
}

.ruleWrapperTitle {
    flex: 0 1 auto;
    text-align: center;
    text-decoration: underline;
}

.fullOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.overlayBox {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid white;
    background-color: rgba(255,255,255,0.3);
    max-width: 80vh;
    padding: 20px;
    margin: 0 auto;
}
.overlayBox img {
    max-height: 50vh;
    max-width: 100%;
    filter: invert(1);
}
.overlayBoxTitle {
    margin: 0;
}

.closeOverlayButton {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.closeOverlayButton img {
    width: 100%;
    height: 100%;
    filter: invert(0);
}

.resetButton {
    border: 1px solid red;
    color: red;
    background-color: rgba(255,0,0,0.3);
    cursor: pointer;
    margin-top: 20px;
}

.inactive {
    background-color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid white;
}

.selected {
    background-color: rgba(255, 255, 255, 0.5);
}