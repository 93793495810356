.teamWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.teamMember {
    flex: 0 1 auto;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.avatarContainer {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
}

.avatarContainer img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.memberName {
    flex: 1 1 auto;
    text-align: center;
    white-space: nowrap;
    font-size: smaller;
}

.memberRole {
    font-style: italic;
    font-size: x-small;
}