.gameWrapper {
    height: 60vh;
}

.gameCanvas {
    width: 100%;
    height: 100%;
}

.operationMenuWrapper {
    position: absolute;
    width: 50%;
    text-align: center;
}

.hide {
    display: none;
}

.tesselationWrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.flipImageWrapper {
    position: relative;
}

.tesselationControls {
    text-align: center;
    object-fit: contain;
    height: 40px;
    padding: 20px;
}

.hexTesselationControlPosition {
    position: absolute;
    bottom: 0;
}

.hexagonRenderer {
    width: 100%;
    height: 100%;
}

.fillingImage {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
}

.fadeIn {
    animation: fadeIn 0.75s forwards;
}

.fadeOut {
    animation: fadeOut 0.25s forwards;
}
  
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}