.wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 30px;
    pointer-events: none;
    color: black;
    padding-top: 20px;
}

.wrapper a {
    color: black;
    text-decoration: none;
}

.wrapper a:visited {
    color: black;
}

.sideMenuButton {
    flex: 0 1 auto;
    margin-top: 10px;
    pointer-events: all;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    writing-mode: vertical-lr;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.75rem;
}

.hidden {
    display: none;
}

.highlight {
    animation: shake 0.5s both;
    transform: translate3d(0, 0, 0);
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}