.menu {
    display: flex;
}

.topicListWrapper {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.topicListEntry {
    cursor: pointer;
}

.explorerWrapper  {
    flex: 1 1 auto;
}

.active {
    color: rgb(255, 120, 120);
}