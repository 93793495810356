.gameWrapper {
    width: 100%;
    height:100%;
}

.gameControls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}

.iconButton {
    flex: 1 1 auto;
    background-color: #fff;
    margin: 10px;
    max-width: 100px;
    cursor: pointer;
}

.iconButton img {
    width: 100%;
}

.hidden {
    display: none;
}