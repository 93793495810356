@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300&display=swap');

body {
  background-color: #0a6630;
  background-image: url("./images/background_new_landscape_tilable.jpg");
  background-size: 1920px;
  font-weight:300;
  -webkit-text-size-adjust: none;
  color: white;
  font-size: 100%;
}

.appBody {
  color: white;
  font-family: 'M PLUS 1p', sans-serif;
  max-width: 2000px;
  margin: 0 auto;
}

h1, h2, h3, p {
  margin: 0 0 15px 0;
  padding: 0;
  min-height: 0;
}

h1 {
  font-size: clamp(1.4rem, 0.3000rem + 1.6000vw, 2.2rem);
  font-weight: 300;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: clamp(1.2rem, 0.3000rem + 1.6000vw, 2rem);
  font-weight: 300;
}

h3 {
  font-size: clamp(1.1rem, 0.3000rem + 1.6000vw, 1.8rem);
  font-weight: 300;
}

.landingpageTitle {
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 20px;
}

.hubLink {
  text-align: center;
}

.layoutLimiter {
  max-width: 1200px;
  margin: auto;
}

.layoutWrapper {
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  justify-content: center;
  min-height: 100vh;
}

.mainDisplay {
  background: rgba(0,0,0,.5);
  padding: 20px;
  flex: 0 1 auto;
}

a:visited {
  color: white;
}