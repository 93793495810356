.headerTitle {
    text-transform: uppercase;
    margin: 20px 0;
    text-shadow: white 0px 0px 10px;
}

.displayContent {
    font-size: clamp(0.9rem, 0.3000rem + 1.6000vw, 1.25rem);
    margin-bottom: 70px;
}

.displayContent a {
    color: white;
}
.displayContent a:visited {
    color: white;
}

.footerWrapper {
    position: fixed;
    height: 50px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.75) 75%, rgba(0,0,0,0) 100%);
}

.footerLimiter {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.homeNavWrapper {
    flex: 0 1 auto;
    max-width: 25%;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
}

.footerHomeIcon {
    flex: 0 1 auto;
    height: 70%;
}

.footerHomeLabel {
    flex: 1 1 auto;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: small;
    text-transform: uppercase;
}

.footerTitleWrapper {
    flex: 0 1 auto;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    font-size: small;
}

.hideOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.footerElemWrapper {
    flex: 1 1 auto;
    margin-left: 2px;
    margin-right: 2px;
}

.footerNavElemWrapper {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footerNavElem {
    border-radius: 15px;
    border: 1px solid white;
    width: 15px;
    height: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activeNavElem {
    position: relative;
    background-color: white;
    width: 50%;
    height: 50%;
    border-radius: 20px;
}

.pageTitle {
    background: #365;
    text-align: left;
    padding: .2em;
    margin-top: 40px;
}

.mediaWrapper {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.smallMediaContent {
    height: 10vh;
    min-height: 50px;
}

.mediumMediaContent {
    height: 300px;
    max-height: 40vh;
}

.largeMediaContent {
    max-height: 70vh;
}

.imageCaption {
    font-size: smaller;
    font-style: italic;
}

.centerText {
    text-align: center;
}

.overlaySliderBox {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    border: 1px solid white;
    height: 25px;
    display: flex;
}
.overlaySliderBox input {
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: text-top;
}

.mediaContent {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
}
  
.canvasContent {
    width: 100%;
    height: 70vh;
}

.invert {
    filter: invert(1);
}

.introButton {
    display: block;
    border: 1px solid white;
    padding: 10px;
    margin: 0 auto;
    margin-top: 10px;
    max-width: 60%;
    text-decoration: none;
    text-align: center;
    background: rgba(255,255,255,0.2);
}

.gameButton {
    border: 1px solid white;
    color: white;
    padding: 5px;
    margin: 0 auto;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: clamp(0.8rem, 0.3000rem + 1.6000vw, 1.2rem);
    max-width: 60%;
    text-decoration: none;
    text-align: center;
    background: rgba(255,255,255,0.2);
    cursor: pointer;
}

.gameButton:disabled {
    color: rgb(128,128,128);
    background: rgba(128,128,128,0.4);
}

.floatingImage {
    float: left;
    padding-right: 10px; 
    padding-bottom: 10px;
    height: 300px;
}

.navArrow {
    position: fixed;
    top: 50%;
    transform: translate(0,-50%);
    height: 30%;
    cursor: pointer;
}

.navArrow img {
    width: 100%;
    max-width: 2.5vw;
    height: 100%;
    object-fit: contain;
    opacity: 1;
}

.leftArrow {
    transform: rotate(180deg) translate(0,50%);
    left: 10px;
}

.rightArrow {
    right: 10px;
}

.navArrowLabel {
    position: absolute;
    font-size: small;
    text-transform: uppercase;
    white-space: nowrap;
    top: 50%;
}

.navArrowLabelRight {
    transform: translate(-50%, -50%) rotate(90deg);
    left: -10px;
}

.navArrowLabelLeft {
    transform: translate(-65%, -50%) rotate(-90deg);
}

.backHubArrow img {
    filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(300deg)
}

.hidden {
    display: none;
}