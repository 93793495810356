.headerNavigationWrapper {
    display: flex;
    flex-direction: row;
}

.headerNavigationSpacer {
    flex: 0 1 auto;
    width: 10px;
    border-bottom: 1px solid white;
}

.headerNavigationElement {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top: 1px solid white;
    min-height: 40px;
    background-color: rgba(255,255,255,0.3);
    cursor: pointer;
}

.headerNavigationFiller {
    flex: 1 1 auto;
    border-bottom: 1px solid white;
}

.sketchpadWrapper {
    display: flex;
    flex-direction: column;
    background-color: rgba(255,255,255,0.3);
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    padding: 1.2em;
}

.introImagesWrapper {
    text-align: center;
    width: 100%;
}

.introImages {
    filter: invert(1);
    padding-right: 10px;
    padding-left: 10px;
}

.introControls {
    padding-bottom: 20px;
    text-align: center;
}
.introControls button {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    border: 1px solid white;
    background-color: rgba(255,255,255,0.6);
    cursor: pointer;
}
.introControls button:disabled {
    border: 1px solid rgb(141, 141, 141);
    background-color: rgba(117, 117, 117, 0.6);
}

.toolboxWrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 20px;
}
.theorySelector {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}
.toolSelector {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
}
.sketchpadControlButtons {
    flex: 0 1 auto;
    margin: 10px 5px 0 5px;
    height: 2rem;
}
.resetButton {
    border: 1px solid red;
    color: red;
    background-color: rgba(255,0,0,0.3);
    cursor: pointer;
}
.checkButton {
    border: 1px solid rgb(173, 255, 47);
    color: #adff2f;
    background-color: rgba(173, 255, 47,0.3);
    cursor: pointer;
}

.sketchpadBody {
    border-bottom: 1px solid white;
    padding-bottom: 10px;
}


.sketchpadFooter {
    min-height: 50px;
    display: flex;
    flex-direction: row;
}
.infoTextWrapper  {
    flex: 1 1 auto;
    color: white;
}
.footerControlsWrapper {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.selectedButton {
    background-color: rgba(255,255,255,0.6);
}



.inactive {
    border-bottom: 1px solid white;
    background-color: rgba(255,255,255,0.6);
}

.errorText {
    color: red;
}

.sketchControlButtons {
    margin-left: 10px;
    margin-right: 10px;
}

.timeArrow {
    height: 75px;
    width: 100%;
    text-align: center;
}

.timeArrowImg {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

